import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import translateService from '@data/translateService';
import fr from '../../configs/i18n/fr.json';

import 'dayjs/locale/fr';
import dayjs from 'dayjs';

dayjs.locale('fr');

// ** i18n config
// const loadResources = async (locale) => {
//   let lang = '';
//   switch (locale) {
// case 'en':
//   lang = 'en_EN';
//   break;
//   default:
//     lang = 'fr_FR';
// }
// const response = await translateService.get(
//   `translations?appUuid=c0391a56-cdf6-5301-a0a1-c0f645f61eb6&languageTag=${lang}`,
//   { timeout: 5000 }
// );

// if (locale === 'en') {
// dayjs.locale('en');
// To replace when the feature will be ready
//     dayjs.locale('fr');
//   } else {
//     dayjs.locale('fr');
//   }

//   return response.data.data[0].Translations;
// };

// const backendOptions = {
//   loadPath: '{{lng}}|{{ns}}',
//   request: (options, url, payload, callback) => {
//     try {
//       const [lng] = url.split('|');
//       loadResources(lng).then((response) => {
//         callback(null, {
//           data: response,
//           status: 200,
//         });
//       });
//     } catch (e) {
//       callback(null, {
//         status: 500,
//       });
//     }
//   },
// };

i18n
  .use(initReactI18next)
  // .use(backend)
  .init(
    {
      fallbackLng: 'fr',
      ns: ['translation'],
      defaultNS: 'translation',
      debug: false,
      load: 'languageOnly',
      keySeparator: false,
      resources: {
        fr: {
          translation: fr,
        },
      },
    },
    (err) => {
      if (err) {
        console.error('Erreur lors du chargement des traductions :', err);
      }
    }
  );

export default i18n;
